#three {
  .background {
    background-image: url(../assets/three-bg.jfif);
  }
  div.container {
    background-color: rgba(0, 0, 0, 0.85);
  }

  img {
    height: 80vh;
  }

  h1 {
    font-size: 2.5rem;
    padding: 1rem;
    line-height: 2.5rem;
    color: #eeeeee;
  }
  div.text {
    padding-left: 4rem;
    h1,
    p {
      color: #eeeeee;
    }

    .description {
      color: #aaaaaa;
    }
  }
}

@media (max-width: 768px) {
  #three {
    img {
      position: relative;
      height: 100vw;
      order: 0;
    }

    h1 {
      order: 1;
    }

    div.text {
      order: 2;
      padding: 0;
    }
  }
}
