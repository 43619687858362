#paper {
  padding: 0 2rem;
  height: initial;
  margin-bottom: 5rem;
  width: 100%;

  .container {
    height: initial;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  div.head {
    background-color: #ffffff;
    margin-left: -2rem;
    width: 100vw;
    height: 8vh;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 0 1.5rem;

    h1 {
      font-size: 1.5rem;
      flex-grow: 1;
    }
  }

  h1.page-heading {
    color: $primary-color;
  }
}
