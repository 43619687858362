.countDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4rem;
  background-image: linear-gradient(to right, #5c39f8, #2196f3);

  h1 {
    color: #fff;

  }
  p{
    color: #ddd;

  }

  &__h1 {
      margin: 1rem -2rem;
  }

  &__p {
      padding: 0.5rem;
      font-size: 1rem;
      margin: 0 -2.5rem;
      line-height: 1.4rem
  }

  .countWrap{
      display: flex;
        justify-content: space-evenly;
  align-items: center;
  width: 100%;
  
  }

  .countContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  h1.countText {
    font-size: 3rem;
    margin: 1rem;
  }

  p.countText {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
}

@media (max-width: 768px) {



  .countDiv {
    .countWrap{
    flex-direction: column;
}

    .countContainer {
      margin: 1rem;
    }

    p.countText {
      font-size: 1.2rem;
    }
  }
}
