.card {
  height: 6rem;
  width: 40vw;
  // background-color: #ddd;
  border: 1px solid #aaa;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: background-color ease-in-out 300ms;

  &:hover {
    // box-shadow: 4px 4px 10px #999;
    background-color: $primary-color;
  }

  &:hover &__title {
    color: #fff;
  }

  &__title {
    color: $primary-color;
    font-weight: 600;
    font-size: 1.5rem;
    transition: color ease-in-out 300ms;
  }

  @media (max-width: 768px) {
    width: 90vw;

    &__title {
      font-size: 1.2rem;
    }
  }
}

#researches {
  max-width: 80vh;

  .research-info-header {
    margin-top: 2rem;
    max-width: 40vw;
  }

  .container {
    margin-top: -10vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 80%;
  }

  .logo {
    position: fixed;
    left: 50%;
    top: 4%;
    transform: translateX(-50%);
    background-image: url(../assets/nosh.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 1.2rem;
    width: 100px;
    margin: auto 0;
  }
}

@media (max-width: 768px) {
  #researches {
    .research-info-header {
      margin-top: 2rem;
      max-width: 90vw;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.3;
    }

    .container {
      padding: 0 1rem;
    }
  }
}
