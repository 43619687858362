@media (max-width: 768px) {
  #nosh-shop {
    height: 60rem;

    .container {
      height: 100%;
      width: 100%;

      .text {
        margin: 0;
      }
    }
  }
}
