$clip-url: url(../assets/noshbig.svg);

.hero-banner {
  background-color: #ffffff;
  background-image: url(../assets/BG.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  height: 90vh;
  width: 100%;
  z-index: 2;
}

.overlay {
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.05),
    rgba(255, 255, 255, 0.2)
  );
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tagline {
  font-size: 1.6rem;
  color: $dark-grey;
  margin-top: 0.75rem;
  margin-left: -0.25rem;
  letter-spacing: 0.2rem;
  margin-bottom: 0.75rem;
}

.sub-tagline {
  font-size: 1.2rem;
  font-weight: 500;
  max-width: 40vw;
  color: $dark-grey;

  text-align: center;
  line-height: 1.8rem;
  opacity: 0.8;
}

.nosh-background {
  height: 40%;
  width: 50%;
  background: linear-gradient(
    to right,
    $primary-color 25%,
    darken($secondary-color, 10%) 50%,
    $primary-color 80%
  );
  background-size: 200% auto;
  animation: ongoing 5s linear infinite;
}

@keyframes ongoing {
  to {
    background-position: 200% center;
  }
}

.clip {
  mask-image: $clip-url;
  -webkit-mask-image: $clip-url;
  mask-mode: alpha;
  -webkit-mask-mode: alpha;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 45%;
  -webkit-mask-size: 45%;
  mask-position: bottom;
  -webkit-mask-position: bottom;
}

.learn-cont {
  padding: 2rem;
  margin-top: 1rem;
  p {
    color: $primary-color;
    font-weight: 700;
    letter-spacing: 0.1rem;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }

  .learn-cont {
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .arrow {
    position: absolute;
    bottom: -25%;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-image: url(../assets/arrow.svg);
    background-size: contain;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  .mouse {
    background-size: contain;
    background-image: url(../assets/mouse.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    padding: 1rem;
    margin-top: 1rem;
  }
}

div.download-buttons {
  height: 70px;
  width: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.android-link > div.download-buttons {
  background-image: url(../assets/google-play-badge.png);
}

.apple-link > div.download-buttons {
  height: 74px;
  width: 174px;
  background-image: url(../assets/app-store-badge.png);
}

div.download-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem;

  h3 {
    width: 180px;
    text-align: center;
  }
}

div.bma,
div.blue-tulip {
  position: absolute;
  height: 60px;
  width: 120px;
  margin: 1rem;
  background-image: url(../assets/BMA.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

div.blue-tulip {
  background-image: url(../assets/blueTulip.png);
  left: 150px;
}

@media (max-width: 768px) {
  .nosh-background {
    width: 60%;
  }
  div.download-links {
    flex-direction: column;
  }
  .clip {
    mask-size: 75%;
    -webkit-mask-size: 75%;
  }
  .tagline {
    font-size: 1.2rem;
    margin-top: 0.75em;
  }

  .sub-tagline {
    line-height: 1.4rem;
    font-size: 1rem;
    max-width: 95vw;
  }

  .learn-cont {
    .arrow {
      bottom: -20%;
    }
  }

  .hero-banner {
    transform: rotate(180deg);

    .overlay {
      transform: rotate(180deg);
      background-image: linear-gradient(
        145deg,
        rgba(0, 0, 0, 0.05),
        rgba(255, 255, 255, 1) 35%,
        rgba(0, 0, 0, 0) 65%
      );
    }
  }

  .hero-banner {
    height: 84vh;
  }

  div.bma,
  div.blue-tulip {
    bottom: 0;
    height: 30px;
    width: 100px;
    z-index: 20;
    transform: rotate(180deg);
  }

  div.blue-tulip {
    left: 0;
    bottom: 50px;
  }
}

@keyframes twerk {
  0%,
  50%,
  100% {
    transform: rotateZ(0deg);
  }

  16.66%,
  66.66% {
    transform: rotateZ(5deg);
  }

  33.34%,
  83.32% {
    transform: rotateZ(-5deg);
  }
}
.clicked {
  animation: twerk 300ms forwards ease-out;
}
