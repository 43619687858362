@media (max-width: 768px) {
  section {
    padding: 0;
  }
  div.container {
    padding: 0;
    flex-direction: column;
  }

  div.text {
    max-width: 100vw;
  }
  p {
    padding: 0.5rem 1.5rem;
  }
  h1,
  div.text > h1,
  #three h1 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
    line-height: 1.5em;
  }

  img {
    height: 100vw;
    margin-top: 2.5rem;
  }
}
