#about {
  background-color: #000000;
  height: 110vh;
  padding: 2rem 0;
  .container {
    height: 95%;
    padding-top: 0;
    align-items: center;
  }

  h1,
  p {
    text-align: center;
    color: #ffffff;
    z-index: 5;
  }

  h1 {
    font-size: 2.1rem;
    padding: 1rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.2rem;
    letter-spacing: 0.1rem;
  }
  p.role {
    position: absolute;
    left: -82px;
    top: 25%;
    transform: rotate(90deg);
    z-index: 0;
    font-size: 28px;
    font-weight: 900;
    color: #111;
  }

  .avatar {
    z-index: 5;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }

  .about-cont {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(
      to bottom,
      $secondary-color,
      $primary-color
    );
    width: 25vw;
    padding: 2rem;
    justify-content: center;
    border-radius: 10px;
  }

  .about-cont:nth-child(2) {
    position: relative;
  }

  .about-cont:nth-child(1) {
    .avatar {
      background-image: url(../assets/somdip.jpeg);
      background-position: top;
    }
  }
  .about-cont:nth-child(2) {
    .avatar {
      background-image: url(../assets/suman.jpeg);
    }
  }
  .about-social {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 1.5rem 20%;

    & a {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      & p {
        padding: 0.5rem;
      }
    }

    & p.fab {
      font-size: 25px;
    }
  }

  .about-person {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.heading {
  display: block;
  margin-top: 10vh;
}

@media (max-width: 768px) {
  #about {
    height: 115vh;
    min-height: 840px;
    .heading {
      margin-top: 3vh;
    }
    .container {
      height: 90%;
      justify-content: space-evenly;
      padding: 0;
    }
    .about-cont {
      padding: 1rem;
      margin: 1rem;
      width: 90vw;
      flex-direction: row;
      padding-left: 3rem;
    }
    .about-cont:nth-child(2) {
      transform: translateY(0);
    }

    h1 {
      font-size: 1.4rem;
      padding: 0.5rem;
      line-height: 1.4rem;
    }

    p.role {
      left: -5.5rem;
      font-size: 1.2rem;
    }

    .avatar {
      height: 80px;
      width: 80px;
      margin: 1rem;
    }

    .about-detail {
      width: 50vw;
      font-size: 12px;
      letter-spacing: 0.1rem;
      line-height: 15px;
    }

    .about-person {
      margin-left: -2rem;
    }
  }
}
