#five {
  background-image: url(../assets/kitchen.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    justify-content: space-between;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    );
  }

  img {
    height: 80vh;
    margin-right: 5vw;
    margin-top: 5vh;
    transform-origin: bottom;
  }

  div.text {
    color: #ffffff;
    margin-left: 5vw;
    width: 30vw;
  }
}

@media (max-width: 768px) {
  #five {
    background-position: center;
    .container {
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: flex-start;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0)
      );
    }

    img {
      order: 1;
      height: 100vw;
      margin-right: 0;
      margin-left: 5vw;
    }

    div.text {
      order: 0;
      color: #ffffff;
      margin-left: 0;
      width: 100%;
    }
  }
}
