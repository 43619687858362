footer {
  height: 45vh;
  width: 100%;
  bottom: 0;
  position: sticky;
}

.covid-banner {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  top: calc(10vh + 1.5rem + 4rem);
  padding: 1rem;
  border-radius: 10px;
  background-image: linear-gradient(to right, #5c39f8, #2196f3);
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1,
  p {
    color: white;
    text-align: center;

    span {
      font-size: 3.5rem;
      display: inline-block;
      transform: translateY(-0.23rem);
      margin: 5px;
      line-height: 0.5rem;
    }
  }
}

.connect__with {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;

  & > h1 {
    color: #5c38f8;
  }

  & > .social-links {
    padding: 2rem;
    display: flex;
    min-width: 300px;
    max-width: 50vw;
    justify-content: space-between;

    & > a {
      transform: scale(1.2);

      & > p {
        color: #111111;
      }
    }
  }
}
