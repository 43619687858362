#why-nosh {
  background-image: url(../assets/pineapple-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  .container {
    justify-content: flex-start;
    background-image: linear-gradient(
      to right,
      #ffffff,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  div.text {
    margin-left: 5vw;
    p {
      margin-top: 1.5rem;
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }
}

@media (max-width: 768px) {
  div.text {
    margin: auto 1.5rem;
  }

  #why-nosh {
    .container {
      background-image: linear-gradient(
        to bottom,
        #ffffff,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.6)
      );
    }
  }
}
