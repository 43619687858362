#four {
  overflow: hidden;

  div.container {
    img {
      height: 90vh;
    }
  }
}

@media (max-width: 768px) {
  #four > div.container {
    img {
      position: relative;
      height: 100vw;
    }
  }
}
