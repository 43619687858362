$primary-color: #5c38f8;
$secondary-color: #2196f3;
$dark-grey: #212121;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  overflow-x: hidden;
}

a.btn {
  padding: 10px 35px;
  color: #444444;
  border: 2px solid #444444;
  border-radius: 10px;
  transition-property: color, background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;

  &:hover {
    color: #000000;
    background-color: rgba(#444444, 0.1);
  }
}

a.btn.btn-light {
  color: #eeeeee;
  border: 2px solid #eeeeee;
  &:hover {
    color: #ffffff;
    background-color: rgba(#eeeeee, 0.1);
  }
}
