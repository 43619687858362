#one {
  overflow: hidden;
  margin-top: -10vh;
  position: relative;

  .condition {
    position: absolute;
    bottom: -15rem;
    font-size: 15px;
    left: 0;
    max-width: 40vw;
    line-height: -1px;
  }

  div.container {
    position: relative;
    overflow: hidden;
    img {
      height: 90vh;
      position: absolute;
      right: 10vw;
      bottom: -10%;
    }

    div.text {
      position: absolute;
      left: 10vw;
      z-index: 2;
    }
  }
}

@media (max-width: 768px) {
  #one {
    height: 120vh;
    .condition {
      position: relative;
      bottom: 0rem;
      font-size: 12;
      max-width: 100vw;
    }

    div.container {
      justify-content: space-evenly;
      height: 100%;
      img {
        position: relative;
        height: 140vw;
        bottom: -30%;
      }

      div.text {
        position: absolute;
        left: auto;
        right: auto;
        top: 15vh;
      }
    }
  }
}
