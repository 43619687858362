$header-height: 8vh;

header {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: $header-height;
  padding: 0 5vw;
  margin-top: 10px;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  z-index: 10;

  a {
    margin: auto 0;
  }

  .logo {
    background-image: url(../assets/nosh.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 1.2rem;
    width: 100px;
    margin: auto 0;
  }

  .web-nav {
    justify-content: space-between;
    min-width: 40rem;
    width: 40vw;
  }

  nav {
    display: flex;
    margin: auto 1rem;

    a {
      color: $primary-color;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.05rem;
    }
  }
}

.mob-nav {
  display: none;
}

@media (max-width: 980px) {
  header {
    padding: 0 1rem;
    nav.web-nav {
      display: none;
    }

    div.mob-nav {
      display: flex;
    }

    .hamburger {
      height: 1.8rem;
      width: 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      z-index: 20;
      margin: auto 1rem;
      transition: transform 300ms ease-out;
    }

    .hamburger > span {
      height: 2.5px;
      width: 100%;
      background-color: $primary-color;
      border-radius: 5px;
      transform-origin: left;
      transition: transform 300ms ease-out, margin 300ms ease-out;
    }

    .hamburger.open {
      transform: rotate(360deg) translateX(2px);
    }

    .hamburger > span:nth-child(2) {
      transform-origin: center;
    }

    .hamburger.open > span:nth-child(2) {
      transform: scale(0);
    }

    .hamburger.open > span:nth-child(1) {
      margin-top: -5px;
      transform: rotate(45deg);
    }
    .hamburger.open > span:nth-child(3) {
      margin-bottom: -5px;
      transform: rotate(-45deg);
    }
  }

  div.drawer {
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    position: fixed;
    height: 110vh;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    transform: scale(2);
    transition: all 300ms ease-out;
  }

  div.drawer.open {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }

  nav.mob-nav {
    margin: auto;
    width: 50%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    a {
      color: $primary-color;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.05rem;
    }
  }

  header.open {
    background-color: transparent;
  }
}
