#privacy-policy {
  padding: 0 2rem;
  height: initial;
  margin-bottom: 5rem;
  width: 100%;
  h1#privacy {
    font-size: 1.5rem;
    flex-grow: 1;
  }
  .container {
    height: initial;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }

  div.head {
    background-color: #ffffff;
    margin-left: -4rem;
    width: 100vw;
    height: 8vh;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 0 1.5rem;
  }
}

.back-button {
  color: $primary-color;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 5.5rem;
  height: 2rem;

  .back-arrow {
    background-image: url(../assets/arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
    height: 1rem;
    width: 1rem;
    margin: auto 0;
  }

  p#back {
    padding: 0;
    padding-left: 1rem;
  }
}

div.sub {
  margin: 1rem 0;
}
.content {
  margin-top: 10vh;
}

p {
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}

h3.sub-section-head {
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: $dark-grey;
}

h1.sub-head {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

@media (min-width: 768px) {
  #privacy-policy {
    h1#privacy {
      text-align: center;
    }
  }
  div.head {
    padding-top: 2rem;
    height: 10vh;
  }
  div.sub {
    width: 80%;
  }

  h1.sub-head {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  #privacy-policy {
    h1#privacy {
      text-align: right;
    }
    .container {
      div.head {
        margin-left: -2rem;
        justify-content: space-between;
      }
    }
  }

  h1.sub-head {
    font-size: 1.4rem;
    color: #424242;
    padding-bottom: 1rem;
  }

  p.matter {
    padding: 0;
  }
}

h3#last-update {
  margin-left: 0;
}
