@import "config";
@import "herobanner";
@import "header";

html {
  scroll-behavior: smooth;
}

h3 {
  color: #858585;
  margin: 2rem;
}

body {
  height: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
}

section {
  height: 100vh;
  min-height: 750px;
  width: 100%;
  background-color: #ffffff;
}

div.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  height: 100vh;
  min-height: 750px;
  padding: 2rem;
  padding-top: $header-height;
  overflow-y: hidden;
}

div.text {
  max-width: 45vw;
  h1 {
    font-size: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 2.5rem;
  }
  p {
    letter-spacing: 0.1rem;
    padding: 1rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }
}

div.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

main {
  transition: transform 300ms ease-out;
  z-index: 2;
  background-color: #ffffff;
}

main.open {
  transform: scale(0.99);
}

.terms-star {
  color: $primary-color;
}

@import "sectionone";
@import "sectiontwo";
@import "sectionthree";
@import "sectionfour";
@import "sectionfive";
@import "sectionNoshShop";
@import "sectionNoshDaily";
@import "about";
@import "footer";
@import "newsArticles";
@import "whynosh";
@import "countDiv";
@import "mobile";
@import "privacypage";
@import "research";
@import "card";
