#two {
  z-index: 2;
  background-color: #000000;

  img {
    position: relative;
    height: 80vh;
  }

  div.text {
    h1,
    p {
      color: #eeeeee;
    }

    .description {
      color: #aaaaaa;
    }
  }
}

@media (max-width: 768px) {
  #two {
    height: 130vh;

    .description{
      height: 40vh;
    }
     
    img {
      position: relative;
      height: 100vw;
    }
  }
}
