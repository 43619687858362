#news-articles {
  padding: 2rem 2rem;
  background-color: white;
  display: flex;
  // height: 250px;
  flex-direction: column;
  align-items: center;

  h1 {
    padding-bottom: 2rem;
  }
}

.news-wrap {
  // height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.news-cont {
  padding: 2rem;
  height: 100px;
  width: 180px;
  margin: 2rem;
  filter: grayscale(100%);
  opacity: 0.4;
  transition-property: opacity filter;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  background-repeat: no-repeat;
  background-size: contain;
}

.news-cont:hover {
  opacity: 1;
  filter: grayscale(0%);
}

.business-weekly {
  background-image: url(../assets/businessweeklyNews.jpg);
}

.business-insider {
  background-image: url(../assets/businessInsider.jpg);
}

.indy-news {
  background-image: url(../assets/indy-news.jpeg);
}

.gadgets-now {
  background-image: url(../assets/gadgetsNow.jpg);
}

.cnbc {
  background-image: url(../assets/cnbcNews.jpg);
}

.tc {
  background-image: url(../assets/techCrunch.jpeg);
  transform: scale(0.8);
}

.bbc {
  background-image: url(../assets/bbc.png);
}

.echo {
  background-image: url(../assets/echo.png);
}

.dailymail {
  background-image: url(../assets/dailyMail.png);
}

.foodtribe {
  background-image: url(../assets/foodtribe.png);
}

@media (max-width: 768px) {
}

@media (max-width: 768px) {
  #news-articles {
    margin-top: -1rem;
    min-height: 1080px;
    height: auto;
  }
  .news-wrap {
    flex-direction: column;
  }

  .news-cont {
    height: 120px;
    margin-bottom: -0.25rem;
    filter: grayscale(0%);
    opacity: 1;
  }
}
